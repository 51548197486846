<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" xl="1" class="py-1">
                  Vigente
                  <v-autocomplete
                    v-model="filtro.vigente"
                    tabindex="1"
                    :items="opciones"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Comprobante
                  <v-text-field
                    v-model.trim="filtro.num_comp"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="3" xl="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados_cheque"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3" xl="2" class="py-1">
                  Tipo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_cheque"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" xl="1" class="py-1">
                  Auditado
                  <v-autocomplete
                    v-model="filtro.auditado"
                    tabindex="1"
                    :items="opciones"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" xl="3" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha emisión desde
                      <FechaPicker
                        v-model="filtro.emision_desde"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col>
                      Fecha emisión hasta
                      <FechaPicker
                        v-model="filtro.emision_hasta"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="5" xl="3" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha vencim. desde
                      <FechaPicker
                        v-model="filtro.vencimiento_desde"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                    <v-col>
                      Fecha vencim. hasta
                      <FechaPicker
                        v-model="filtro.vencimiento_hasta"
                        :clearable="true"
                        :hideDetails="true"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Formato
                  <v-autocomplete
                    v-model="filtro.formato"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="formatos_cheque"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5" md="4" xl="3" class="py-1">
                  Origen
                  <v-autocomplete
                    v-model="filtro.origen"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="origenes"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Código Gestor
                  <v-text-field
                    v-model.trim="filtro.gestor"
                    ref="gestorCod"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @blur="buscar_gestor()"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        class="mt-1"
                        color="info"
                        title="Buscar gestor"
                        small
                        @click="dialog_gestor = true"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  <v-text-field
                    v-model.trim="nombre_gestor"
                    class="pt-6"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Nº Liquidación
                  <v-text-field
                    v-model.trim="filtro.num_liqui"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  clase="mt-0"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        item-key="id"
        sort-by="num_comp"
        :items="cheques"
        :headers="headers"
        :search="search"
        :loading="load"
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        show-select
        sort-desc
        dense
        @toggle-select-all="selectAllToggle"
      >
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="item.estado_id == 1"
          :value="isSelected"
          :readonly="item.disabled"
          :disabled="item.disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
              <DownloadDataTable
                name="Cheques - Jarvis 2"
                :data="cheques.map(item => {
                  let copia = JSON.parse(JSON.stringify(item))
                  copia.importe = copia.importe.toString().replace('.', ',')
                  return copia
                })"
                :headers="headers_excel"
              />
              <v-btn
                color="info"
                title="Detalle de totales"
                :disabled="cheques.length == 0"
                icon
                @click="dialog_totales = true"
              >
                <v-icon>fas fa-info-circle</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- check auditado -->
        <template v-slot:[`item.auditado`]="{ item }">
          <v-icon v-if="item.auditado == 1" color="success" small>fas fa-check-circle</v-icon>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <!-- fila de totales -->
        <template slot="body.append">
          <tr class="font-weight-bold">
            <th colspan="3" style="font-size: 14px" >Total</th>
            <th style="font-size: 14px" class="text-right">
              {{ formatMoney(cheques.reduce((sum, ch) => sum + ch.importe, 0)) }}
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </template>
        <!-- acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            title="Ver"
            color="info"
            small
            @click="open_dialog(item, 'dialog')"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            title="Historial"
            color="indigo"
            class="ml-2"
            small
            @click="open_dialog(item, 'dialog_hist')"
          >
            fas fa-history
          </v-icon>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- BOTONES -->
      <v-row class="d-flex justify-end mx-0 mt-1 mb-3">
        <SelectedCounter
          :selected.sync="selected"
          :items.sync="cheques"
          :custom="true"
          @criterio="select_all()"
        />
        <v-spacer></v-spacer>
        <BtnConfirmar
          color="indigo"
          clase="mt-2"
          icono="fas fa-mail-bulk"
          :nombre="`Cambiar estado a ${estados_cheque.find(ec => ec.id == 2) ? estados_cheque.find(ec => ec.id == 2).nombre : 'depositado'}`"
          :texto="`¿Desea cambiar el <strong>estado</strong> de los <strong>${selected.length}</strong> cheques seleccionados a
          <strong>${estados_cheque.find(ec => ec.id == 2) ? estados_cheque.find(ec => ec.id == 2).nombre : 'depositado'}</strong>?`"
          :dark="selected.length > 1"
          :disabled="selected.length <= 1"
          @action="cambiar_estado()"
        />
      </v-row>
    </v-col>
    <!-- COMPONENTES -->
    <ModalGestor
      v-model="dialog_gestor"
      @seleccion="seleccionar_gestor"
    />
    <Cheque
      v-model="dialog"
      :p_id="cheque_id"
      @editar="actualizar_cheque"
    />
    <HistorialModifCheque
      v-model="dialog_hist"
      :p_id="cheque_id"
    />
    <!-- modal de totales -->
    <v-dialog
      v-model="dialog_totales"
      width="500"
    >
      <v-card>
        <v-card-title>
          Detalle de totales
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog_totales = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Concepto</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(estado, index) in estados_cheque"
                  :key="index"
                >
                  <td style="font-size: 14px">{{ estado.nombre }}</td>
                  <th style="font-size: 14px" class="text-right">
                    {{ formatMoney(cheques.filter(che => che.estado_id  == estado.id).reduce((sum, ch) => sum + ch.importe, 0)) }}
                  </th>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="secondary"
            @click="dialog_totales = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { format_money } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import ModalGestor from '../../components/auditoria/ModalGestor'
import SearchDataTable from '../../components/util/SearchDataTable'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import Cheque from '../../components/auditoria/Cheque'
import HistorialModifCheque from '../../components/auditoria/HistorialModifCheque'
import DownloadDataTable from '../../components/util/DownloadDataTable'
import SelectedCounter from '../../components/util/SelectedCounter'

export default {
  data () {
    return {
      formatMoney: format_money,
      panel: 0,
      load: false,
      dialog: false,
      dialog_hist: false,
      dialog_gestor: false,
      dialog_totales: false,
      nombre_gestor: '',
      search: '',
      cheque_id: null,
      cheques: [],
      selected: [],
      opciones: [
        { text: 'SI', value: 1 },
        { text: 'NO', value: 0 }
      ],
      headers: [
        { text: 'Origen', value: 'origen' },
        { text: 'Nº Comp.', value: 'num_comp', align: 'end' },
        { text: 'Importe', value: 'importe', align: 'end',  formatter: format_money },
        { text: 'Emisión', value: 'emision' },
        { text: 'Vencimiento', value: 'vencimiento' },
        { text: 'Estado', value: 'estado' },
        { text: 'Tarjeta', value: 'tarjeta' },
        { text: 'Nº Liq.', value: 'num_liquidacion' },
        { text: 'Auditado', value: 'auditado', align: 'center' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      headers_excel: {
        'AUDITADO': 'auditado',
        'ORIGEN': 'origen',
        'NÚMERO COMPROBANTE': 'num_comp',
        'IMPORTE': 'importe',
        'EMISIÓN': 'emision',
        'VENCIMIENTO': 'vencimiento',
        'ESTADO': 'estado',
        'TARJETA': 'tarjeta'
      },
      filtro: {
        formato: null,
        tipo: null,
        origen: null,
        estado: null,
        num_comp: null,
        emision_desde: null,
        emision_hasta: null,
        vencimiento_desde: null,
        vencimiento_hasta: null,
        gestor: null,
        num_liqui: null,
        auditado: null,
        vigente: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('movBancarios/get_origenes')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('cheques', ['tipos_cheque', 'estados_cheque', 'formatos_cheque']),
    ...mapState('movBancarios', ['origenes']),
  },
  components: {
    BtnFiltro,
    FechaPicker,
    ModalGestor,
    SearchDataTable,
    BtnConfirmar,
    Cheque,
    HistorialModifCheque,
    DownloadDataTable,
    SelectedCounter
  },
  methods: {
    async cambiar_estado () {
      this.$store.state.loading = true
      await this.$store.dispatch('cheques/cambiar_estado', this.selected.map(ch => ch.id))
        .then((res) => {
          this.buscar()
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.num_comp && !this.filtro.emision_desde && this.filtro.estado != 1) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese una Fecha de emisión o un Nº de comprobante',
          color: 'orange'
        })
      }
      this.selected = []
      this.cheques = []
      this.search = ''
      this.load = true
      await this.$store.dispatch('cheques/get_cheques', this.filtro)
        .then((res) => {
          this.cheques = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    actualizar_cheque (datos) {
      let cheque = this.cheques.find(c => c.id == datos.id)
      delete cheque.emision
      delete cheque.vencimiento
      delete cheque.estado
      cheque.emision = datos.emision
      cheque.vencimiento = datos.vencimiento
      cheque.estado = datos.estado
    },
    async buscar_gestor () {
      const codigo = this.filtro.gestor
      if (codigo) {
        this.$store.state.loading = true
        await this.$store.dispatch('movBancarios/get_gestores', { codigo: codigo })
          .then((res) => {
            switch (res.size) {
              case 0:
                this.filtro.gestor = null
                this.nombre_gestor = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró ningún gestor con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
              case 1:
                this.nombre_gestor = res.data[0].nombre
                break
              default:
                this.filtro.gestor = null
                this.nombre_gestor = ''
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontraron múltiples coincidencias para el gestor con código ' + codigo,
                  color: 'error'
                })
                this.$refs.gestorCod.focus()
                break
            }
          })
          .catch(error => {
            this.filtro.gestor = null
            this.nombre_gestor = ''
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
            this.$refs.gestorCod.focus()
          })
        this.$store.state.loading = false
      }
    },
    open_dialog (item, dialog) {
      this.cheque_id = item.id
      this[dialog] = true
    },
    seleccionar_gestor (gestor) {
      this.filtro.gestor = gestor.codigo
      this.nombre_gestor = gestor.nombre
    },
    select_all () {
      this.selected = []
      this.cheques.forEach(che => {
        if (che.estado_id == 1) {
          this.selected.push(che)
        }
      })
    },
    selectAllToggle () {
      if (this.selected.length < this.cheques.filter(ch => ch.estado_id == 1).length) this.select_all()
      else this.selected = []
    },
    limpiar () {
      this.nombre_gestor = ''
      this.filtro = {
        formato: null,
        tipo: null,
        origen: null,
        estado: null,
        num_comp: null,
        emision_desde: null,
        emision_hasta: null,
        vencimiento_desde: null,
        vencimiento_hasta: null,
        gestor: null,
        num_liqui: null,
        auditado: null,
        vigente: null
      }
    }
  }
}
</script>